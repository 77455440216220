import Swiper, { Navigation, Pagination, Autoplay, Keyboard } from 'swiper';
import 'swiper/swiper-bundle.css';

const container = document.querySelector('.swiper-container');

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, Keyboard]);

const swiper = new Swiper('.swiper-container', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    observer: true,
    simulateTouch: true,
    slideToClickedSlide: false,
    touchEventsTarget: "wrapper",
    keyboard: {
        enabled: true,
        onlyInViewport: false,
    },
    autoplay: {
        delay: 3000,
        disableOnInteraction: true
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    },
    breakpoints: {
        640: {
            slideToClickedSlide: true,
            slidesPerView: 2,
            spaceBetween: 0,
        },
    }
});

setTimeout(() => {
    container ? container.style.opacity = 1 : null;
}, 300);
